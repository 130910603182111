import React, {
    useState,
    useMemo,
    isValidElement,
    cloneElement,
    Children,
} from "react"
import type { ComponentType, ReactNode } from "react"

const calculatePrice = (tokens: number) => {
    let tier = packages[0].tier
    packages.forEach((p) => {
        if (p.tokens <= tokens) {
            tier = p.tier
        }
    })

    return tier
}

const calculateEnterprise = (price: number) => {
    return Math.round(Math.min(Math.max(price * 0.49, 988), 1966))
}

const replaceValues = (string, replacements) => {
    console.log(string, replacements)

    return string
        .replace(/{c}/g, replacements.currency)
        .replace(/{ps}/g, replacements.price_starter.toLocaleString())
        .replace(/{p}/g, replacements.price.toLocaleString())
        .replace(/{pe}/g, replacements.price_ent.toLocaleString())
}

const replaceStringsInChildren = (children, replacements) =>
    Children.map(children, (child) => {
        if (isValidElement(child)) {
            if (typeof child.props.children === "string") {
                const newProps = { ...(child.props as any) } as any
                const newChildren = replaceValues(
                    child.props.oldText ?? child.props.children,
                    replacements
                )

                newProps.children = newChildren

                console.log(newProps)

                return cloneElement(child, newProps)
            } else if (child.props.children) {
                return cloneElement(
                    child,
                    child.props,
                    ...replaceStringsInChildren(
                        child.props.children,
                        replacements
                    )
                )
            }
        }
        return child
    })

export function withPricing(Component: ComponentType): ComponentType {
    return (props: any) => {
        const [currency, setCurrency] = useState("€")
        const [tokens, setTokens] = useState(100)
        const enterPriseMarkup = calculateEnterprise(calculatePrice(tokens))
        const params = useMemo(
            () => ({
                currency,
                price_starter: calculatePrice(tokens),
                price: calculatePrice(tokens),
                price_ent: calculatePrice(tokens) + enterPriseMarkup + 1100,
            }),
            [currency, tokens]
        )

        const onChange = (e: React.ChangeEvent<HTMLFormElement>) => {
            // change currency
            if (e.target.name === "currency") {
                if (e.target.value.includes("£")) {
                    setCurrency("£")
                } else if (e.target.value.includes("€")) {
                    setCurrency("€")
                }
            }

            if (e.target.name === "tokens") {
                setTokens(
                    parseInt(e.target.value.replace(",", "").replace(".", ""))
                )
            }
        }

        const replacedChildren = replaceStringsInChildren(
            props.children,
            params
        )

        console.log(params, { tokens, currency }, replacedChildren)

        return (
            <form
                style={{ display: "flex", alignSelf: "center", width: "100%" }}
                onChange={onChange}
            >
                <Component {...props} children={replacedChildren} />
            </form>
        )
    }
}

const packages = [
    {
        tokens: 100,
        price: 0.4,
        tier: 34.5,
    },
    {
        tokens: 250,
        price: 0.3,
        tier: 72,
    },
    {
        tokens: 500,
        price: 0.15,
        tier: 122,
    },
    {
        tokens: 1000,
        price: 0.12,
        tier: 197,
    },
    {
        tokens: 2500,
        price: 0.12,
        tier: 347,
    },
    {
        tokens: 5000,
        price: 0.12,
        tier: 547,
    },
    {
        tokens: 7500,
        price: 0.12,
        tier: 722,
    },
    {
        tokens: 10000,
        price: 0.1,
        tier: 872,
    },
    {
        tokens: 15000,
        price: 0.08,
        tier: 1122,
    },
    {
        tokens: 25000,
        price: 0.08,
        tier: 1522,
    },
    {
        tokens: 50000,
        price: 0.08,
        tier: 2272,
    },
    {
        tokens: 75000,
        price: 0.07,
        tier: 2772,
    },
    {
        tokens: 100000,
        price: 0.07,
        tier: 3147,
    },
    {
        tokens: 150000,
        price: 0.07,
        tier: 3647,
    },
    {
        tokens: 250000,
        price: 0.07,
        tier: 4647,
    },
    {
        tokens: 500000,
        price: 0.07,
        tier: 6897,
    },
    {
        tokens: 750000,
        price: 0.06,
        tier: 8897,
    },
    {
        tokens: 1000000,
        price: 0.06,
        tier: 10647,
    },
]
